import loadable from "@loadable/component"
import React from "react"

import ConversionPanelCard from "./ConversionPanelCard/ConversionPanelCard"

const ConversionPanelV1 = loadable(() => import("./ConversionPanelV1"))
const ConversionPanelV2 = loadable(() => import("./ConversionPanelV2"))

const ConversionPanel = ({ component, grid, pricing }) => {
  if (component?.variant === "Card") {
    return <ConversionPanelCard component={component} />
  }

  if (component?.newDesign) {
    return (
      <ConversionPanelV2 pricing={pricing} component={component} grid={grid} />
    )
  }
  return <ConversionPanelV1 component={component} grid={grid} />
}

export default ConversionPanel
