import React, { useState, useCallback } from "react"
import { StructuredText } from "react-datocms"

import OptimizedImage from "../../../utils/OptimizedImage"
import ModalForm from "../ModalForm"

import {
  ConversionPanelWrapper,
  ConversionPanelContainer,
  PanelContentWrapper,
  PanelCTAContainer,
  EyebrowText,
  PanelHeading,
  DescriptionBodyContainer,
  CTALink,
  PanelImageContainer,
  PanelImageFlexWrapper,
  ButtonModal,
} from "./ConversionPanelCard.styled"

const ConversionPanelCard = ({ component }) => {
  const [open, setOpen] = useState(false)
  const callToAction =
    component?.conversionReference?.length > 0 && component?.conversionReference

  const cardImg =
    component?.cardImage?.desktop || component?.cardImage?.mobile || null
  const withModalForm = component?.withModalForm
  const hubspotFormId = component?.hubspotFormId

  const handleModal = useCallback(() => setOpen(!open), [open])

  return (
    <>
      <ConversionPanelWrapper>
        <ConversionPanelContainer>
          <PanelContentWrapper>
            {component.eyebrow && (
              <EyebrowText>{component.eyebrow}</EyebrowText>
            )}
            {component.heading && (
              <PanelHeading>{component.heading}</PanelHeading>
            )}

            {component?.conversionBody && (
              <DescriptionBodyContainer>
                <StructuredText data={component.conversionBody} />
              </DescriptionBodyContainer>
            )}

            <PanelCTAContainer>
              {callToAction.length > 0 &&
                callToAction.map((ctaLink, idx, ctaArray) => {
                  const isLast = idx === ctaArray.length - 1

                  if (ctaLink?.button && withModalForm) {
                    return (
                      <ButtonModal key={ctaLink.id} onClick={handleModal}>
                        {ctaLink.label}
                      </ButtonModal>
                    )
                  }

                  return (
                    <CTALink isLast={isLast} key={ctaLink.id} to={ctaLink.url}>
                      {ctaLink.label}
                    </CTALink>
                  )
                })}
            </PanelCTAContainer>
          </PanelContentWrapper>

          {cardImg && (
            <PanelImageFlexWrapper>
              <PanelImageContainer>
                <OptimizedImage
                  image={cardImg?.gatsbyImageData}
                  alt={cardImg?.title || component?.heading}
                />
              </PanelImageContainer>
            </PanelImageFlexWrapper>
          )}
        </ConversionPanelContainer>
      </ConversionPanelWrapper>
      <ModalForm open={open} formId={hubspotFormId} onClose={handleModal} />
    </>
  )
}

export default ConversionPanelCard
