import React from "react"
import HubspotForm from "react-hubspot-form"

import {
  ModalFormContainer,
  ModalContent,
  CloseModal,
  CloseButton,
} from "./ConversionPanelCard/ConversionPanelCard.styled"

const ModalForm = ({ open, formId, onClose }) => (
  <ModalFormContainer open={open}>
    <ModalContent>
      <CloseModal>
        <CloseButton onClick={onClose}>X</CloseButton>
      </CloseModal>
      <HubspotForm
        portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
        formId={formId}
      />
    </ModalContent>
  </ModalFormContainer>
)

export default ModalForm
