import { Link } from "gatsby"
import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const ConversionPanelWrapper = styled.div`
  background: #fff;

  padding: 40px 5%;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`
export const ConversionPanelContainer = styled.div`
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;

  border-radius: 24px;
  background: #f8fcff;
  box-shadow: 0px 10px 20px -2px rgba(16, 24, 40, 0.05),
    0px 24px 24px -4px rgba(16, 24, 40, 0.05);

  @media (min-width: 577px) {
    max-width: 650px;
    padding: 64px 70px;
  }

  @media (min-width: 993px) {
    max-width: 770px;
    padding: 64px 70px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    min-width: 1170px;
    max-height: 384px;
  }
`
export const PanelContentWrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: 993px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 500px;
  }
`
export const PanelCTAContainer = styled.div`
  @media (min-width: 993px) {
    display: flex;
  }
`

export const ButtonModal = styled.button`
  outline: none;
  text-align: center;

  background-color: ${color.primary[200]};
  border-radius: 40px;
  border: 1px solid ${color.primary[200]};
  color: ${color.copy["900"]};
  padding: 12px 24px;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  width: 100%;

  @media (min-width: 768px) {
    width: fit-content;
  }

  &:hover {
    background-color: ${color.primary["500"]};
  }
`

export const EyebrowText = styled.p`
  color: #1a8000;

  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.7px;
  text-transform: uppercase;

  margin: 0 0 16px;

  @media (min-width: 577px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const PanelHeading = styled.h1`
  color: #000c30;

  font-family: Avenir, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  margin-bottom: 16px;

  @media (min-width: 577px) {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  @media (min-width: 993px) {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.96px;
  }
`
export const DescriptionBodyContainer = styled.div`
  margin-bottom: 32px;

  & > p {
    color: #55575c;
    font-family: Avenir, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px;

    margin-bottom: 0;

    @media (min-width: 577px) {
      font-size: 18px;
      font-weight: 350;
      line-height: 24px;
    }

    @media (min-width: 993px) {
      font-size: 20px;
      font-weight: 350;
      line-height: 30px;
    }
  }
`
export const CTALink = styled(Link)`
  display: block;
  text-align: center;

  background: #62ddfc;

  border-radius: 40px;
  border: 1px solid #62ddfc;
  color: #17191d;
  padding: 10px 18px;

  height: fit-content;

  ${({ isLast }) =>
    isLast
      ? `
    border-color: #17191D;
    background-color: transparent;
  `
      : `
      
      margin-bottom: 16px;
      @media (min-width: 577px) {
        margin-right: 20px;
        margin-bottom: 0px;
      }
      `}

  transition: all 450ms ease-in-out;
  cursor: pointer;

  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;

  @media (min-width: 577px) {
    padding: 11px 24px;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
  }

  &:hover {
    text-decoration: none;
    background: #00c4f0;
    color: #000c30;
  }

  @media (min-width: 577px) {
    display: inline-block;
  }
`
export const PanelImageContainer = styled.div`
  position: relative;
  min-width: 295px;
  min-height: 198.737px;

  @media (min-width: 577px) {
    max-width: 630px !important;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
export const PanelImageFlexWrapper = styled.div`
  display: contents;

  @media (min-width: 577px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 993px) {
    position: relative;
    width: fit-content;
  }
`

export const ModalFormContainer = styled.div`
  display: ${(props) => (props?.open ? "flex" : "none")};
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.7);
  padding: 16px;

  @media (orientation: landscape) {
    display: ${(props) => (props?.open ? "block" : "none")};
  }

  @media (min-width: 768px) {
    display: ${(props) => (props?.open ? "flex" : "none")};
  }
`

export const ModalContent = styled.div`
  position: relative;
  align-self: center;
  margin: 0 auto;
  width: 100%;
  max-width: 770px;
  height: fit-content;
  overflow: auto;
  background: ${color.common.white};
  padding: 24px;
  box-shadow: rgba(16, 24, 40, 0.05) 0 20px 30px;
  border-radius: 6px;

  @media (min-width: 992px) {
    padding: 40px;
  }

  .hs-form {
    .hs-form-field {
      margin: 12px 0;
      .hs-input {
        background: ${color.common.white};
        border: 1px solid ${color.gray[300]};
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 10px 14px;
        width: 100%;
        font-weight: 350;
        font-size: 16px;
        line-height: 24px;
        color: ${color.gray[900]};
        &.error {
          border: 1px solid ${color.common.error};
        }
        &:focus {
          border: 1px solid ${color.primary[400]};
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
            0 0 0 4px ${color.primary[50]};
        }
        &:focus-visible {
          outline: ${color.primary[400]} auto 1px;
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
            0 0 0 4px ${color.primary[50]};
        }
        #placeholder {
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          color: ${color.gray[500]};
        }
      }
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${color.gray[700]};
        .hs-form-required {
          color: ${color.common.required};
        }
      }
      &.hs-fieldtype-booleancheckbox {
        .input {
          .inputs-list {
            list-style: none;
            margin-right: 0;
            margin-bottom: 0;
            padding-inline-start: 0;
            li {
              input {
                background: ${color.common.white};
                border: 1px solid ${color.gray[300]};
                border-radius: 4px;
                margin-right: 8px;
                width: max-content;
              }
              label {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: ${color.gray[700]};
              }
            }
          }
        }
      }
      &.hs-fieldtype-select {
        select {
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          color: ${color.gray[500]};
        }
      }
    }
    .hs-main-font-element {
      font-weight: bold;
      div {
        margin-bottom: 0;
      }
    }
    .legal-consent-container {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${color.gray[700]};
      }
    }
    .legal-consent-container .hs-form-booleancheckbox-display > span {
      display: flex;
      align-items: center;
    }
    .legal-consent-container .hs-form-booleancheckbox-display > input {
      margin-top: 4px;
    }

    .hs-submit {
      input {
        height: 42px;
        width: 100%;
        padding: 11px 20px;
        background: ${color.primary[200]};
        border: 1px solid ${color.primary[200]};
        border-radius: 40px;
        font-weight: 900;
        font-size: 14px;
        line-height: 20px;
        color: ${color.copy[900]};
        margin-top: 32px;
        &:hover {
          background: ${color.primary[400]};
        }
        &:focus {
          background: ${color.primary[200]};
          border: 2px solid ${color.common.purple};
          color: ${color.common.white};
        }
        &:active {
          background: ${color.primary[900]};
        }
      }
    }
    .hs-error-msgs {
      list-style: none;
      padding-left: 0;
      margin-top: 3px;
    }
  }
`

export const CloseModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: bolder;
  padding: 0;
  margin: 0;
  color: ${color.common.black};
`
